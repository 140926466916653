<template>
  <div class="main">
    <h1 class="h1">{{$t('InvoiceInformation')}}</h1>
    <Tabs v-model="activeName" @tab-click="handleTabClick">
      <TabPane :label="$t('All')" name="ALL"></TabPane>
      <TabPane :label="$t('Unpaid')" name="WAIT_BUYER_PAY"></TabPane>
      <TabPane :label="$t('Paid')" name="TRADE_FINISHED"></TabPane>
    </Tabs>
    <div class="order-list">
      <div class="order-item" v-for="(item) of list" :key="item.id">
        <div class="hd">
          <span v-if="isPaid(item.tradeStatus)">{{$t('TradeStatus')[item.tradeStatus]}}</span>
          <span v-else-if="isUnpaid(item.tradeStatus) && !isPaymentTimeout(item.firstPaymentTime)">{{$t('InvoiceRemaining')}}：{{ moment(remainingPaymentTime(item.firstPaymentTime)).format('mm:ss') }}</span>
          <span v-else-if="isUnpaid(item.tradeStatus) && isPaymentTimeout(item.firstPaymentTime)">{{$t("InvoiceTimeout")}}</span>
          <span v-else>{{$t('InvoiceRemaining')}}：{{$t('NotApplicable')}}</span>
          <span :class="isUnpaid(item.tradeStatus) ? 'fc1' : 'fc2'">
            {{$t('Trade status')}}：{{$t('TradeStatus')[item.tradeStatus]}}
            <el-tooltip :content="$t('InvoiceContinuePay')" placement="bottom" effect="light">
              <el-button v-if="isUnpaid(item.tradeStatus) && !isPaymentTimeout(item.firstPaymentTime)" type="danger" icon="el-icon-right" circle size="mini" @click="openWindow(item.eftpayApptrade)"/>
            </el-tooltip>
            <el-tooltip :content="$t('InvoiceUpdateTradeStatus')" placement="bottom" effect="light">
              <el-button v-if="isUnpaid(item.tradeStatus)" type="danger" icon="el-icon-refresh-right" circle size="mini" :loading="list.filter(i=>i.id === item.id)[0].loading" @click="refreshPaymentStatus(item.payPlatform.toLowerCase(),item.id)"></el-button>
            </el-tooltip>
          </span>
        </div>
        <div class="bd cl">
          <div class="infos">{{item.lineTitle}} / {{item.lineName}}</div>
          <div class="infos">{{$t('InvoicePlatform')}}:{{$t(item.payPlatform)}}</div>
          <div class="infos">{{$t('InvoicePayOrderId')}}:{{item.payOrderId}}</div>
          <div class="infos">{{$t('InvoiceOrderNo')}}:{{item.orderNo}}</div>
          <div class="infos">{{$t('CreationTime')}}:{{moment.utc(item.addDate).local().format('YYYY-MM-DD HH:mm:ss')}}</div>
          <span>{{$t('InvoiceId')}}：{{item.id}}</span>
          <div :class="isUnpaid(item.tradeStatus) ? 'price' : 'price-paid'">{{$t('Total')}}：{{item.billingType|currencySymbol}}{{item.totalMoney}}</div>
        </div>
        <div class="ft" @click.stop>
          <a class="btn-primary" @click="orderContinuePay(item.id)" v-if="item.orderStatus==='WAIT_BUYER_PAY'">
            {{$t('Payment')}}
          </a>
          <a class="btn-primary" href="https://unutravel.com/themetravel" v-if="item.orderStatus==='TRADE_FINISHED'">
            {{$t('Book again')}}
          </a>
        </div>
      </div>
    </div>
    <empty v-if="isEmpty" :title="$t('NoData')"/>
    <el-pagination class="pages"
                   background
                   layout="prev, pager, next"
                   :page-count="totalPages"
                   @current-change="changePage"
                   hide-on-single-page>
    </el-pagination>
  </div>
</template>
<script>
  import Empty from '../../components/Empty';
  import {scrollToY} from '../../utils';
  import {getOrderPayStatus} from '../../api/order';
  import {getInvoiceList, checkPaymentStatus} from '../../api/invoice';

  export default {
    name: 'OrderList',
    data() {
      return {
        activeName: '',
        orderNumber: '',
        list: [],
        totalPages: 1,
        isEmpty: false,
        options: [{
          value: 'option1',
          label: '未支付'
        }, {
          value: 'option2',
          label: '已付款'
        }, {
          value: 'option3',
          label: '成功報名'
        }, {
          value: 'option4',
          label: '確認成團'
        }, {
          value: 'option5',
          label: '已完成'
        }, {
          value: 'option6',
          label: '付款逾時'
        }, {
          value: 'option7',
          label: '訂單已取消'
        }, {
          value: 'option8',
          label: '商戶取消，待客確定'
        }, {
          value: 'option9',
          label: '已確認取消'
        }, {
          value: 'option10',
          label: '已調團'
        }],
        value: ''
      };
    },
    components: {Empty},
    mounted() {
      this.activeName = this.$route.query.active || 'WAIT_BUYER_PAY';
    },
    methods: {
      handleTabClick(tabName) {
      },
      refreshPaymentStatus(payPlatform, orderInvoiceId) {
        // start loading
        this.list.filter(i => i.id === orderInvoiceId)[0].loading = true;
        checkPaymentStatus(payPlatform, orderInvoiceId)
          .then(msg => {
            this.$msgbox({
              title: this.$t('InvoiceSuccessMessageboxTitle'),
              message: this.$t('InvoiceSuccessMessageboxContent'),
              center: true,
              lockScroll: false,
              beforeClose: (action, instance, done) => {
                this.activeName = 'TRADE_FINISHED';
                this.getInvoiceList();
                done();
              }
            });
          }).catch(e => {
          }).finally(() => {
            this.list.filter(i => i.id === orderInvoiceId)[0].loading = false;
          });
      },
      filterOrderNumber(queryString, cb) {
        // const passenger = [{value: '111111'}, {value: '222222'}, {value: '333333'}];
        const passenger = [];
        const results = queryString ? passenger.filter(this.createFilter(queryString)) : passenger;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      // 获取我的订单列表
      getInvoiceList(page = 1) {
        const data = {page, perPage: 10, tradeStatus: this.activeName === 'ALL' ? '' : this.activeName};
        getInvoiceList(data).then(res => {
          const {value} = res;
          const list = value.list || [];
          list.map(i => { i.loading = false; });

          console.log(list);

          // for (let i = 0; i < list.length; i++) {
          //   if (list[i].tradeStatus === 'WAIT_BUYER_PAY' && (Date.now() - list[i].firstPaymentTime > 900000)) {
          //     list.splice(i, 1);
          //     i--;
          //   }
          // }

          this.list = list;
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
          this.$nextTick(() => {
            scrollToY();
          });
        });
      },
      changePage(page) {
        this.getInvoiceList(page);
      },
      // 打开详情页
      openDetail(id) {
        const routeUrl = this.$router.resolve({name: 'OrderDetail', params: {id}});
        window.open(routeUrl.href, '_blank');
      },
      // 继续付款
      orderContinuePay(orderId) {
        const loading = this.$loading({text: 'Loading'});

        // 检查当前订单是否已支付
        getOrderPayStatus(orderId).then(res => {
          loading.close();
          if (res.value === 'success') {
            this.$message.success('當前訂單已付款');
          } else {
            const routeUrl = this.$router.resolve({
              name: 'ConfirmOrder',
              query: {orderId: orderId, active: 'PAYMENT', type: 'CONTINUE'}
            });
            window.open(routeUrl.href, '_blank');
          }
        }).catch(() => {
          loading.close();
        });
      },
      isPaid(tradeStatus) {
        return tradeStatus === 'TRADE_FINISHED';
      },
      isUnpaid(tradeStatus) {
        return tradeStatus === 'WAIT_BUYER_PAY';
      },
      isPaymentTimeout(firstPaymentTime) {
        return Date.now() - firstPaymentTime > 900000;
      },
      remainingPaymentTime(firstPaymentTime) {
        return 900000 - (Date.now() - firstPaymentTime);
      },
      openWindow(link) {
        window.open(link);
      }
    },
    watch: {
      activeName: function () {
        this.list = [];
        this.totalPages = 1;
        this.getInvoiceList();
      }
    }
  };
</script>
<style scoped lang="less">
  .filters{
    margin:20px 0;
    .label{line-height:34px;}
  }
  .ipt-autocomplete{width:220px;}
  .order-item{
    margin-bottom:8px;padding:20px; padding-bottom:10px ; background-color:#f4f4f4;border-radius:4px;
    .hd{
      line-height:30px;border-bottom:1px dashed #e0e0e0;padding-bottom: 10px;
      span{display:inline-block;margin-right:1px;font-weight: bold;}
    }
    .bd{margin-top:8px;}
    .ft{margin-top:15px;text-align:right;font-size:0;}
    .infos{display:flex;margin-right:150px;}
    .price{float:right;color:#ff6f61;font-size: 16px;font-weight: bold;}
    .price-paid{float:right;color:#67c23a;font-size: 16px;font-weight: bold;}
    .btn-primary,
    .btn-gray{
      display:inline-block;margin-left:10px;padding:0 12px;font-size:12px;border-radius:4px;cursor:pointer;user-select:none;
      &:hover{opacity:0.8;}
    }
    .btn-primary{line-height:20px;color:#fff;background-color:#ff6f61;}
    .btn-gray{line-height:18px;color:#321908;border:1px solid #e6e6e6;background-color:#fff;}
    .fc1{float:right;color:#ff6f61;}
    .fc2{float:right;color:#67c23a;}
  }
</style>
