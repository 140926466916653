<template>
  <div>
    <el-row>
      <el-col :xs="24" :sm="8">
        <img :src="item.photoUrl" width="100%" max-width="500px">
      </el-col>
      <el-col :xs="24" :sm="14" class="detail">
        <div class="hd">
          <span>{{ $t('Order number') }}：{{ item.orderNo }}</span>
          <span>{{ $t('Scheduled time') }}：{{ item.addDate|formatDate('yyyy-MM-dd hh:mm') }}</span>
          <span :class="{fc1:item.orderStatus==='NOT_PAYMENT'}">
            {{ $t('Order status') }}：{{ $t('UOrderStatus')[item.orderStatus] }}
          </span>
          <span v-if="(item.orderStatus==='NOT_PAYMENT') && (Date.now() - item.firstPaymentTime < 900000)">
            <Countdown ref="countdown"/>
          </span>
        </div>
        <div class="bd cl">
<!--          <div class="price">{{ $t('Total') }}：{{ item.billingType|currencySymbol }}{{ item.totalMoney }}</div>-->
          <div class="infos">{{ item.productTitle }} / {{ item.businessName }}</div>
        </div>
        <div class="bd cl">
          <div class="price">{{ $t('Total') }}：{{ item.billingType|currencySymbol }}{{ item.totalMoney }}</div>
        </div>
        <div class="ft" @click.stop>
          <!--取消订单(未支付的)-->
          <a class="btn-gray" @click="updateOrderStatus(index,item.orderId,'CANCEL')"
             v-if="item.orderStatus==='NOT_PAYMENT'">
            {{ $t('Cancel order') }}
          </a>
          <!--取消订单(已支付的)-->
          <div v-if="item.orderSource !== 'mission'">
            <a class="btn-gray"
               @click="cancelOrder(index,item.orderId,item.orderNo)"
               v-if="['NOT_SIGN_UP_SUCCESS','NOT_GROUP','YES_GROUP'].includes(item.orderStatus)">
              {{ $t('Cancel order') }}
            </a>
          </div>
          <a class="btn-primary" @click="orderContinuePay(item.orderId)" v-if="item.orderStatus==='NOT_PAYMENT'">
            {{ $t('Payment') }}
          </a>
<!--          <a class="btn-primary" href="https://unutravel.com/themetravel" v-if="item.orderStatus==='FINISH'">-->
<!--            {{ $t('Book again') }}-->
<!--          </a>-->
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import Countdown from '../../../components/Countdown.vue';

export default {
  name: 'OrderListItem',
  props: {
    item: Object,
    index: Number
  },
  components: {Countdown},
  created() {
    this.item.addDate = this.item.addDate + (8 * 60 * 60 * 1000);
  },
  mounted() {
    if (this.item.orderStatus === 'NOT_PAYMENT') {
      this.$refs.countdown.start(900000 - (Date.now() - this.item.firstPaymentTime));
    }
  },
  methods: {
    cancelOrder(index, orderId, orderNo) {
      this.$emit('cancelOrder', index, orderId, orderNo);
    },
    orderContinuePay(orderId) {
      this.$emit('orderContinuePay', orderId);
    },
    updateOrderStatus(index, orderId, status) {
      this.$emit('updateOrderStatus', index, orderId, status);
    }
  }
};
</script>

<style scoped lang="less">
.detail{
  padding-left: 20px;
}

.hd {
  line-height: 28px;
  border-bottom: 1px dashed #e0e0e0;

  span {
    display: inline-block;
    margin-right: 30px;
  }
}

.bd {
  margin-top: 8px;
}

.ft {
  margin-top: 15px;
  text-align: right;
  font-size: 0;
}

.infos {
  display: flex;
}

.price {
  float: right;
  color: #ff6f61;
}

.btn-primary,
.btn-gray {
  display: inline-block;
  margin-left: 10px;
  padding: 0 12px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
}

.btn-primary {
  line-height: 20px;
  color: #fff;
  background-color: #ff6f61;
}

.btn-gray {
  line-height: 18px;
  color: #321908;
  border: 1px solid #e6e6e6;
  background-color: #fff;
}

.fc1 {
  color: #ff6f61;
}
</style>
