<template>
  <div class="main">
    <h1 class="h1">{{$t('OrderInformation')}}</h1>
    <Tabs v-model="activeName" @tab-click="handleTabClick">
      <TabPane :label="$t('All')" name="ALL"></TabPane>
      <TabPane :label="$t('ComingSoon')" name="YES_GROUP"></TabPane>
      <TabPane :label="$t('Departed')" name="FINISH"></TabPane>
    </Tabs>
    <div class="filters cl">
     <el-row class="full row" >
      <el-col :xs="24" :sm="12" :span="12" class="fl">
        <!--訂單編號-->
        <div class="fl label">{{$t('Order number')}}：</div>
        <div class="fl">
          <el-autocomplete class="ipt-autocomplete"
                           size="small"
                           v-model="orderNumber"
                           :fetch-suggestions="filterOrderNumber"
                           :placeholder="$t('PleaseEnter')">
          </el-autocomplete>
        </div>
      </el-col >
      <el-col :xs="24" :sm="12" :span="12" class="fr">
        <div class="fl label">{{$t('Order status')}}：</div>
        <div class="fl">
          <el-select v-model="value" clearable :placeholder="$t('PleaseEnter')">
            <el-option v-for="(item,key) of $t('OrderStatus')"
                       :key="key"
                       :label="item"
                       :value="key">
            </el-option>
          </el-select>
        </div>
      </el-col>
     </el-row>
    </div>
    <div class="order-list">
      <div class="order-item" v-for="(item,index) of list" :key="item.orderId" @click="openDetail(item.orderId)" style="cursor: pointer">
        <OrderListItem :item="item" :index="index" @orderContinuePay="orderContinuePay" @cancelOrder="cancelOrder" @updateOrderStatus="updateOrderStatus" />
      </div>
    </div>
    <empty v-if="isEmpty" :title="$t('NoData')"/>
    <el-pagination class="pages"
                   background
                   layout="prev, pager, next"
                   :page-count="totalPages"
                   @current-change="changePage"
                   hide-on-single-page>
    </el-pagination>
    <cancel-order ref="cancelOrder"/>
  </div>
</template>
<script>
  import Empty from '../../components/Empty';
  import CancelOrder from './components/CancelOrder';
  import {scrollToY} from '../../utils';
  import {getMyOrderList, getOrderPayStatus, updateOrderStatus} from '../../api/order';
  import OrderListItem from './components/OrderListItem.vue';

  export default {
    name: 'OrderList',
    data() {
      return {
        activeName: '',
        orderNumber: '',
        list: [],
        totalPages: 1,
        isEmpty: false,
        value: ''
      };
    },
    components: {Empty, CancelOrder, OrderListItem},
    mounted() {
      this.activeName = this.$route.query.active || 'ALL';
      // this.getList();
    },
    methods: {
      handleTabClick(tabName) {
      },
      filterOrderNumber(queryString, cb) {
        // const passenger = [{value: '111111'}, {value: '222222'}, {value: '333333'}];
        const passenger = [];
        const results = queryString ? passenger.filter(this.createFilter(queryString)) : passenger;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      // 获取我的订单列表
      getList(page = 1) {
        const data = {page, perPage: 10, orderStatus: this.activeName === 'ALL' ? this.value : this.activeName, orderNo: this.orderNumber};
        getMyOrderList(data).then(res => {
          const {value} = res;
          const list = value.list || [];
          this.list = list;
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
          this.$nextTick(() => {
            scrollToY();
          });
        });
      },
      changePage(page) {
        this.getList(page);
      },
      // 打开详情页
      openDetail(id) {
        const routeUrl = this.$router.resolve({name: 'OrderDetail', params: {id}});
        window.open(routeUrl.href, '_self');
      },
      // 取消订单
      cancelOrder(index, orderId, orderNo) {
        this.$refs.cancelOrder.show(orderId, orderNo, () => {
          this.list[index].orderStatus = 'USER_CHECK_REFUND';
        });
      },
      // 继续付款
      orderContinuePay(orderId) {
        const loading = this.$loading({text: 'Loading'});

        // 检查当前订单是否已支付
        getOrderPayStatus(orderId).then(res => {
          loading.close();
          if (res.value === 'success') {
            this.$message.success('當前訂單已付款');
          } else {
            const routeUrl = this.$router.resolve({
              name: 'ConfirmOrder',
              query: {orderId: orderId, active: 'PAYMENT', type: 'CONTINUE'}
            });
            window.open(routeUrl.href, '_blank');
          }
        }).catch(() => {
          loading.close();
        });
      },
      // 修改订单状态(取消未支付的订单)
      updateOrderStatus(index, orderId, orderStatus) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.cancelOrder'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              updateOrderStatus({orderId, orderStatus}).then(res => {
                this.list[index].orderStatus = orderStatus;
                instance.confirmButtonLoading = false;
                done();
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    },
    watch: {
      activeName: function () {
        this.list = [];
        this.totalPages = 1;
        this.getList();
      },
      value: function () {
        this.list = [];
        this.totalPages = 1;
        this.getList();
      },
      orderNumber: function () {
        this.list = [];
        this.totalPages = 1;
        this.getList();
      }
    }
  };
</script>
<style scoped lang="less">
  .filters{
    margin:20px 0;
    .label{line-height:34px;}
  }
  .ipt-autocomplete{width:220px;}
  .order-item{
    margin-bottom:8px;padding:20px;background-color:#f4f4f4;border-radius:4px;
  }
  @media only screen and (max-width: 768px) {
  .fr {
    margin-top: 10px;
  }
}
</style>
