<template>
  <div class="table border">
    <table>
      <thead>
      <tr>
        <th>{{$t('Category')}}</th><!--類別-->
        <th>{{$t('Project')}}</th><!--項目-->
        <th>{{$t('Refund fee')}}</th><!--退回-->
        <th>{{$t('Deduction fee')}}</th><!--扣減-->
        <th>{{$t('Remarks')}}</th><!--備註-->
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) of details" :class="{divider:showLine(index)}" :key="item.detailsId">
        <td colspan="5" v-if="item.cancelCategory==='last'">
          <!--退款總額-->
          <div class="total">{{$t('Total refund')}}：{{item.feeAmount}}</div>
        </td>

        <template v-else-if="item.cancelCategory==='newOrder'&&item.feeAmount>=0">
          <td class="b" style="background-color:#fff" :rowspan="rowSpan(index)" v-if="showLine(index)">
            {{item.cancelCategory|dictName('cancelCategory')}}
          </td>
          <td>{{item.cancelCategoryName}}</td>
          <td>{{item.feeAmount}}</td>
          <td></td>
          <td>{{item.memo}}</td>
        </template>

        <template v-else-if="item.cancelCategory==='newOrder'&&item.feeAmount<0">
          <td class="b" style="background-color:#fff" :rowspan="rowSpan(index)" v-if="showLine(index)">
            {{item.cancelCategory|dictName('cancelCategory')}}
          </td>
          <td>{{item.cancelCategoryName}}</td>
          <td></td>
          <td>{{item.feeAmount}}</td>
          <td>{{item.memo}}</td>
        </template>

        <template v-else>
          <td class="b" style="background-color:#fff" :rowspan="rowSpan(index)" v-if="showLine(index)">
            {{item.cancelCategory|dictName('cancelCategory')}}
          </td>
          <td>{{item.cancelCategoryName}}</td>
          <td>{{item.feeType==='plus'?item.feeAmount:''}}</td>
          <td>{{['negative','deduction'].includes(item.feeType)?item.feeAmount:''}}</td>
          <td>{{item.memo}}</td>
        </template>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  export default {
    name: 'RefundTable',
    props: {
      details: Array
    },
    computed: {
      list() {
        if (!this.details) {
          return [];
        }
        return this.details;
      }
    },
    methods: {
      showLine(index) {
        const prevItem = this.details[index - 1];
        const cancelCategory = this.details[index].cancelCategory;
        return !prevItem || prevItem.cancelCategory !== cancelCategory;
      },
      rowSpan(index) {
        const nextItem = this.details[index + 1];
        const cancelCategory = this.details[index].cancelCategory;
        if (nextItem && nextItem.cancelCategory === cancelCategory) {
          let rowSpan = 0;
          for (let i = index; i < this.details.length; i++) {
            const item = this.details[i];
            if (item.cancelCategory === cancelCategory) {
              rowSpan += 1;
            } else {
              break;
            }
          }
          return rowSpan;
        }
        return 1;
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../assets/style/table";
  .table.border{
    margin-top:0;
    .total{padding-top:10px;text-align:right;font-size:18px;font-weight:bold;}
    .divider{
      td{border-top:1px solid #f4f4f4;}
    }
  }
</style>
