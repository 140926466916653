import request from '@/utils/request';

// 获取我的订单列表
export function getInvoiceList(data) {
  return request({
    url: '/invoice/list',
    method: 'get',
    params: data
  });
}

// 更新付款通知的付款狀態
export function checkPaymentStatus(payPlatform, orderInvoiceId) {
  return request({
    url: `/pay/verify/${payPlatform}/${orderInvoiceId}`,
    method: 'get'
  });
}
